<template>
  <div id="nav">
    <img
      src="https://tuango3.s3.us-east-2.amazonaws.com/assets/logo-tuango-orange.svg"
      class="logo"
      alt=""
    />
    <!--<router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>-->
    <select v-model="stage">
      <option value="dev">Sandbox</option>
      <option value="prod">Production</option>
    </select>
  </div>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  data() {
    return {
      stage: "dev"
    };
  },
  provide() {
    return {
      apiStage: computed(() => this.stage)
    };
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 960px; /* 2 */

  padding-right: 10px; /* 3 */
  padding-left: 10px; /* 3 */
}

#nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px solid #ccc;

  .logo {
    display: block;
    width: 70px;
  }

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
