<template>
  <div class="home">
    <api-doc></api-doc>
  </div>
</template>

<script>
import ApiDoc from "@/components/ApiDoc";
export default {
  name: "Home",
  components: { ApiDoc }
};
</script>
