<template>
  <div id="api-doc"></div>
</template>

<script>
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

// @ is an alias to /src
import specDev from "@/apis/mc-dev.spec";
import specProd from "@/apis/mc-prod.spec";

export default {
  name: "ApiDoc",
  inject: ["apiStage"],
  watch: {
    "apiStage.value": {
      handler(stage) {
        this.$nextTick(() => {
          const spec = stage === "prod" ? specProd : specDev;
          SwaggerUI({
            dom_id: "#api-doc",
            spec
          });
        });
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
